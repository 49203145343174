<template>
  <b-modal id="Modaladdproperties" hide-footer title="Add Properties">
    <div class="row">
      <div class="col-12">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Key code"
            v-model="data.key_code"
            maxlength="1"
          />
        </div>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Description"
            v-model="data.description"
          />
        </div>
        <p class="text-muted font-0-8s">
          หมายเหตุ : Xมอก.ภาคบังคับ1 (Key Code = X)
        </p>
        <button class="btn bt-main w-100" @click="confirm()">ADD</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "Modaladdproperties",
  data() {
    return {
      data: {
        key_code: "",
        description: "",
      },
    };
  },
  methods: {
    validate() {
      let data = this.data;
      if (data.key_code !== "" && data.description !== "") {
        return false;
      } else {
        return true;
      }
    },
    async confirm() {
      try {
        if (this.validate()) {
          throw `กรุณาระบุชื่อคีย์เเละคำอธิบาย/ Please Enter Key code and Description`;
        }
        let data = this.data;

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `attribute/addproperties`,
          data,
          1
        );

        this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        this.redata();
        this.hide();
        this.$emit("getproperties");
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    redata() {
      this.data = {
        key_code: "",
        description: "",
      };
    },
    hide() {
      this.$bvModal.hide("Modaladdproperties");
    },
  },
};
</script>

<style>
</style>