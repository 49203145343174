<template>
  <b-modal id="Modaleditproperties" hide-footer title="Edit Properties">
    <div class="row">
      <div class="col-12">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Key code"
            v-model="data.key_code"
            maxlength="1"
          />
        </div>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Description"
            v-model="data.description"
          />
        </div>
        <p class="text-muted font-0-8s">
          หมายเหตุ : Xมอก.ภาคบังคับ1 (Key Code = X)
        </p>
        <button class="btn bt-main w-100" @click="confirm()">EDIT</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "Modaleditproperties",
  props: ["data_prop"],
  data() {
    return {
      data: {
        key_code: "",
        description: "",
      },
    };
  },
  watch: {
    props_data_prop: function (v) {
      this.getpropertiesbyid();
    },
  },
  computed: {
    props_data_prop() {
      return this.data_prop;
    },
  },
  mounted() {
    this.getpropertiesbyid();
  },
  methods: {
    async getpropertiesbyid() {
      try {
        if (this.data_prop.properties_id !== undefined) {
          let data = { id: this.data_prop.properties_id };
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/getpropertiesbyid`,
            data,
            1
          );
          this.data = {
            key_code: getAPI.data[0].key_code,
            description: getAPI.data[0].description,
          };
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },

    validate() {
      let data = this.data;
      if (data.key_code !== "" && data.description !== "") {
        return false;
      } else {
        return true;
      }
    },
    async confirm() {
      try {
        if (this.validate()) {
          throw `กรุณาระบุชื่อคีย์เเละคำอธิบาย/ Please Enter Key code and Description`;
        }
        let data = {
          key_code: this.data.key_code,
          description: this.data.description,
          id: this.data_prop.properties_id,
        };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `attribute/editproperties`,
          data,
          1
        );

        this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        this.redata();
        this.hide();
        this.$emit("getproperties");
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    redata() {
      this.data = {
        key_code: "",
        description: "",
      };
    },
    hide() {
      this.$bvModal.hide("Modaleditproperties");
    },
  },
};
</script>

<style>
</style>